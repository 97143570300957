<template>
  <section class=''>
    <div class='p-2 border'>
      <div class='my-1 flex flex-row'>
        <label>펀드별 수요예측 신청 배분 보고 드리오니 재가 바랍니다.</label>
      </div>
      <div class='my-1 mb-8 flex flex-row'>
        <label>- 아     래 -</label>
      </div>
      <div class='my-1 flex flex-row'>
        <label class='block w-40 lg:w-48 text-sm lg:text-base'>1. 종목명</label>
        {{ selectedIpo.name }}
      </div>
      <div class='my-1 flex flex-row'>
        <label class='block w-40 lg:w-48 text-sm lg:text-base'>2. 증권사</label>
        {{ brokerNames }}
      </div>
      <div class='my-1 flex flex-row'>
        <label class='block w-40 lg:w-48 text-sm lg:text-base'>3. 수요예측일</label>
        {{ selectedIpo.predicted_date }}
      </div>
      <div class='my-1 flex flex-row'>
        <label class='block w-40 lg:w-48 text-sm lg:text-base'>4. 청약일</label>
        {{ selectedIpo.subscription_date }}
      </div>
      <div class='my-1 flex flex-row'>
        <label class='block w-40 lg:w-48 text-sm lg:text-base'>5. 납입일</label>
        {{ selectedIpo.payment_date }}
      </div>
      <div class='my-1 flex flex-row'>
        <label class='block w-40 lg:w-48 text-sm lg:text-base'>6. 참여계좌별세부내역</label>
      </div>
      <table class=''>
        <thead>
          <tr class='border border-b text-sm text-center'>
            <th class='w-24 border-l'>계좌구분</th>
            <th class='w-24 border-l'>참여단가</th>
            <th class='w-24 border-l'>신청수량</th>
            <th class='w-24 border-l'>확약여부</th>
            <th class='w-24 border-l'>청약단가</th>
            <th class='w-24 border-l'>청약수량</th>
            <th class='w-24 border-l'>청약금액</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='summary in filteredSummaries'
            :key='summary.account_type'
            class='border border-b text-sm text-center'>
            <td class='w-24 border-l'>{{accountTypeName(summary.account_type)}}</td>
            <td class='w-24 border-l text-right'>{{numberStyle(summary.estimated_price)}}</td>
            <td class='w-24 border-l text-right'>{{numberStyle(summary.estimated_quantity)}}</td>
            <td class='w-24 border-l'>{{summary.lock_up_period}}</td>
            <td class='w-24 border-l text-right'>{{numberStyle(allocatedPrice(summary))}}</td>
            <td class='w-24 border-l text-right'>{{numberStyle(summary.allocated_quantity)}}</td>
            <td class='w-24 border-l text-right'>{{numberStyle(allocatedAmount(summary))}}</td>
          </tr>
        </tbody>
      </table>
      <div class='mt-8 ml-2'>
        <label class='block w-40 lg:w-48 text-sm lg:text-base whitespace-no-wrap'>* 청약단가는 수수료 1% 포함</label>
        <label class='block w-40 lg:w-48 text-sm lg:text-base whitespace-no-wrap'>* 세부 배분표는 첨부파일 참고</label>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState }  from 'vuex'

export default {
  name: 'IpoDocsWave',
  data () {
    return {
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
      'selectedIpoId',
      'ipoSummaries',
    ]),
    ...mapState('brokers', [
      'brokers',
    ]),
    filteredBrokers () {
      return this.brokers && this.selectedIpo.broker_ids ? this.brokers.filter(broker => this.selectedIpo.broker_ids.includes(broker.id)) : []
    },
    brokerNames () {
      return this.filteredBrokers.map(broker => broker.name).join(',\n')
    },
    filteredSummaries () {
      return this.ipoSummaries.filter(summary => ['집합', '벤처', '하이일드'].includes(summary.account_type) && summary.estimated_quantity > 0)
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    accountTypeName (accountType) {
      return accountType === '집합' ? '벤처外' : accountType
    },
    allocatedPrice (summary) {
      return summary.allocated_price + (summary.allocated_price * summary.subs_fee_rate / 100)
    },
    allocatedAmount (summary) {
      return summary.allocated_quantity * this.allocatedPrice(summary)
    },
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply py-3 pl-2 pr-2 border text-gray-700 whitespace-no-wrap bg-gray-200;
  }

  td {
    @apply py-3 pl-2 pr-2 border text-gray-700 whitespace-no-wrap;
  }
</style>
